import './App.css';
import Home from './components/Home/home';
import React from "react";
import "./styles.css";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Authenticate from './components/AuthUI'


const RouteElement = ({ location, from, children }) => {
  const { pathname } = location
  const LoggedinFlag = window.localStorage.getItem('spaceio-key')
  return pathname === '/login' ? (LoggedinFlag ?
    <Navigate to={from} /> : (children)) : (LoggedinFlag ? children :
      <Navigate to={'/login'} state={{ from: location }} />)
}

const ProtectedRoute = ({ children, path }) => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  return <RouteElement location={location} from={from} children={children} caseSensitive />
}

function App() {
  return (
    <div className="container-fluid g-0">

      <div className="row justify-content-center g-0 mainDiv">

        <Router>
          <Routes>
            <Route index element={<Authenticate />}></Route>
            <Route path='login' element={<ProtectedRoute><Authenticate /></ProtectedRoute>}></Route>
            <Route path='home' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
          </Routes>
        </Router>


      </div>
    </div>
  );
}

export default App;
