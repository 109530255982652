
import "./styles.css";
import { useState } from "react";
import _ from 'lodash'
import QrReader from "react-qr-reader";
import 'reactjs-popup/dist/index.css';

const App = (props) => {
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);

    const handleScan = async (scanData) => {
        if (!_.isEmpty(scanData)) {
            props.getQRData(scanData);
            setLoadingScan(false);
        }
    }
    const handleError = (err) => {
        console.error(err);
    };

    return (
        <div className="App">
            <>
                {/* <select onChange={(e) => { setSelected(e.target.value) }}>
                    <option value={"environment"}>Back Camera</option>
                    <option value={"user"}>Front Camera</option>
                </select> */}
                {props.Open && <QrReader
                    facingMode={selected}
                    delay={1000}
                    onError={handleError}
                    onScan={handleScan}
                    // chooseDeviceId={()=>selected}
                    style={{ width: "300px" }}
                />}
            </>
            {loadingScan && <p>Loading</p>}
        </div>
    );
};
export default App;
