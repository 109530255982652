import React, { useState } from "react";
import "./home.css"
import Header from "../Header";
import InfoBox from "../infoBox/InfoBox"
import Footer from "../Footer.jsx";
import Button1 from "../Button1"
import QRScan from "../../qr-reader.component";
import App from "../../qr-reader.component";
import _ from 'lodash';
import 'reactjs-popup/dist/index.css';
import Axios from "axios";
import AuthService from "../../Auth/auth.js"
import config from "../../config/index.js";


function Home(props) {
    const [selected, setSelected] = useState("environment");
    const [loadingScan, setLoadingScan] = useState(false);
    const [header, setHeader] = useState("QR Code Mapping")
    const [subHeader, setsubHeader] = useState("Scan QR Code");
    const [scanQR, setScanQR] = useState(false);
    const closeQrScanner = () => setScanQR(false);
    const [scanFrontQr, setScanFrontQr] = useState(true);
    const [QRAssetId, setQRAssetId] = useState("");
    const [QRFrontData, setFrontQRData] = useState(null);
    const [QRBackData, setBackQRData] = useState(null);
    const [defaultPageQr, setdefaultPageQr] = useState(null);
    const [assetId, setassetId] = useState(null);
    const [FrontQr, setFrontQr] = useState(null);

    const getQRData = (data) => {
        if (scanFrontQr && data && !_.isEmpty(data) && (data.match(/spotco.ai/) || data.match(/sqr.ai/))) {
            setFrontQRData(data);
            const url = new URL(data);
            let queryString = url.pathname.split('/');
            const id = queryString[queryString.length - 1];
            setFrontQr(id);
            console.log("id", id);
            setScanFrontQr(false);
        }
        else if (!scanFrontQr && data && !_.isEmpty(data) && (data.match(/q.afreespace.com/) || data.match(/pam.afreespace.com/) || data.match(/beta.afreespace.com/))) {
            setBackQRData(data);

            let myValue = null;

            const url = new URL(data);
            const urlSearchParams = new URLSearchParams(new URL(url).search);

            if (urlSearchParams.get('qrCode')) {
                myValue = urlSearchParams.get('qrCode');
            } else {
                const url = new URL(data);
                let queryString = url.pathname.split('/');
                myValue = queryString[queryString.length - 1];
            }

            getAssetId(myValue);
            setdefaultPageQr(myValue);
            console.log("value", myValue);

        } else {
            alert("Invalid QR code scanned!");
        }
        setScanQR(false);
    }

    async function handleScanQr(e) {
        e.preventDefault();
        setScanQR(true);
    }
    async function getAssetId(BackQR) {
        try {
            const res = await Axios.get(`${config.PRODWS_URL}/devices/qrcode/${BackQR}`, {
                headers: {
                    "x-auth-key": await AuthService.fetchAuthKey()
                }
            });
            setQRAssetId(res.data);
            setassetId(res.data);
            return res.data;
        }
        catch (err) {
            throw (err.response.data.error)
        }
    }

    async function writeDataToSpot(e, data) {
        try {
            e.preventDefault();
            let dataToWrite = [
                {
                    "assetId": assetId,
                    "qrCode": FrontQr,
                    "defaultPage": `${config.PAM_BASE_URL}/?qrCode=${defaultPageQr}`
                }
            ]
            console.log("dataToWrite", dataToWrite);

            let res = await Axios.post(`${config.SPOT_BASE_URL}/tags/qr`, dataToWrite, {
                headers: {
                    "x-location-token": await AuthService.fetchAuthKey(),
                    "authorization": 'K7YZ5LJ3XEJ3',
                    "Content-Type": "application/json"
                },
            },
            );
            setScanQR(false);
            setFrontQRData(null);
            setBackQRData(null);
            setScanFrontQr(true);
            alert("QR Linked!");
        } catch (err) {
            throw (err.response.data.error)
        }
    }

    return (

        <div className="col-lg-12 p-0">
            {/* <QRScan /> */}
            <Header header={header} subHeader={subHeader} />
            <div className="container-fluid body">
                <form className="col-xl-4 col-lg-5 col-sm-6 col-10" style={{ margin: "2%", flexGrow: "1", outline: "1px " }}>
                    <App />
                    <div>
                        <InfoBox text={"Scan QR Code"} value={"environment"} />
                        {
                            <QRScan Open={scanQR} closeQrScanner={closeQrScanner} getQRData={getQRData} />
                        }
                        <Button1
                            text={!_.isEmpty(QRFrontData) && !_.isEmpty(QRBackData) ? 'Submit' : !_.isEmpty(QRFrontData) ? 'Scan Back QR Code' : 'Scan Front QR Code'}
                            borderRadius={"10px"}
                            background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                            color={"#ffffff"}
                            onClick={(e) => { !_.isEmpty(QRFrontData) && !_.isEmpty(QRBackData) ? writeDataToSpot(e) : handleScanQr(e) }}
                        />
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
}

export default Home;